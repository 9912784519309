import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import '../assets/sass/new-age.scss';

export const Layout = ({ children }) => {
  return (
    <Fragment>
      <div className={'page-top'}>{children}</div>
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noFooter: PropTypes.bool,
  noHeader: PropTypes.bool,
  noSiteHeader: PropTypes.bool,
  activeLink: PropTypes.string,
};

export default Layout;
